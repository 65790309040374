import { apiClient } from '@zep/api/api';
import { setApiErrorLogger } from '@zep/api/apiUtil';
import { getSafetyApiToken } from '@zep/utils/getSafetyApiToken';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { axiosConfig } from './axiosConfig';
import { getTraceId } from './getTraceId';

export const AXIOS_INSTANCE = Axios.create(axiosConfig);
AXIOS_INSTANCE.interceptors.response.use(
  res => {
    if (res.status === 200 && res.data.status !== 'SUCCESS') {
      throw new ZepQuizApiError(
        res.data.message || '',
        res.data.status,
        res.status,
        res.data,
      );
    }

    return res;
  },
  (error: AxiosError<{ message?: string; data?: any; status: string }>) => {
    setApiErrorLogger(error);

    if (error.response?.data?.status) {
      throw new ZepQuizApiError(
        error.response.data?.message || 'Unknown error',
        error.response.data?.status || 'Error',
        error.response.status,
        error.response.data,
      );
    }
    throw error;
  },
);

AXIOS_INSTANCE.interceptors.request.use(
  config => {
    config.headers['X-ZEP-Trace-Id'] = getTraceId();

    if (!config.headers['Authorization'] && getSafetyApiToken()) {
      config.headers['Authorization'] = `Bearer ${getSafetyApiToken()}`;
    }

    if (config.params && config.params.request) {
      const requestParams = config.params.request;

      if (requestParams) {
        config.params = {
          ...config.params,
          ...requestParams,
        };
        delete config.params.request;
      }
      // 빈 문자열 값을 가진 키 삭제
      config.params = Object.fromEntries(
        Object.entries(config.params).filter(([, value]) => value !== ''),
      );
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export const setHttpClientToken = (token: string) => {
  apiClient.setToken(token);
  AXIOS_INSTANCE.defaults.headers['Authorization'] = `Bearer ${token}`;
};

export class ZepQuizApiError<T = any> extends Error {
  public details: T;
  public status: string;
  public httpStatus: number;

  constructor(
    message: string,
    status: string,
    httpStatus: number,
    details?: T,
  ) {
    super(message);
    this.name = 'ZepQuizApiError';
    this.details = details || ({} as T);
    this.status = status;
    this.httpStatus = httpStatus;
    this.message = message;
    console.error('ZEP API ERROR :' + JSON.stringify(details), message);
    Object.setPrototypeOf(this, ZepQuizApiError.prototype);
  }
}

// use your own URL here or environment variable

// add a second `options` argument here if you want to pass extra options to each generated query
export const httpsInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): CancellablePromise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data) as CancellablePromise<T>;

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};
interface CancellablePromise<T> extends Promise<T> {
  cancel: () => void;
}

export const enum HttpStatusCode {
  OK_200 = 200,
  CREATED_201 = 201,
  NO_CONTENT_204 = 204,
  BAD_REQUEST_400 = 400,
  UNAUTHORIZED_401 = 401,
  FORBIDDEN_403 = 403,
  NOT_FOUND_404 = 404,
  NOT_ACCEPTABLE_406 = 406,
  PAYLOAD_TOO_LARGE_413 = 413,
  UNSUPPORTED_MEDIA_TYPE_415 = 415,
  UNPROCESSABLE_ENTITY_422 = 422,
  LOCKED_423 = 423,
  TOO_MANY_REQUESTS_429 = 429,
  INTERNAL_SERVER_ERROR_500 = 500,
  BAD_GATEWAY_502 = 502,
  SERVICE_UNAVAILABLE_503 = 503,
  GATEWAY_TIMEOUT_504 = 504,
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = ZepQuizApiError<Error>;

export type BodyType<BodyData> = BodyData;
