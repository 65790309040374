;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"c27b4490e07901b71e009bf87aa58bd18b7d0fce"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { createBeforeSend } from './src/utils/sentry';
import baseOptions from './sentry.base.config';

Sentry.init({
  ...baseOptions,
  beforeSend: createBeforeSend,
  enableTracing: false, // 성능 하락 방지를 위해 비활성화
});
