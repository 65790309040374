import { useLocale } from '@zep/hooks';

export const enum CategoryQueryString {
  All = 'all',
  BEST = 'best',
  PUBLIC = 'public',
}

export const isBestCategoryDefault = (locale: string) =>
  ['ko', 'en', 'ja'].includes(locale);

export const getDefaultCategoryName = (locale: string, category: string) => {
  if (!category) {
    return isBestCategoryDefault(locale)
      ? CategoryQueryString.BEST
      : CategoryQueryString.All;
  }
  return category;
};

export const usePreferCategory = (categories: string[]) => {
  const { locale } = useLocale();
  const preferBestCategory = isBestCategoryDefault(locale);

  if (categories.length === 0) {
    return {
      isSelectedBest: preferBestCategory,
      isSelectedAll: !preferBestCategory,
    };
  }

  if (categories.length === 1) {
    return {
      isSelectedBest: categories[0] === CategoryQueryString.BEST,
      isSelectedAll: categories[0] === CategoryQueryString.All,
    };
  }

  return {
    isSelectedBest: false,
    isSelectedAll: false,
  };
};

export const getOrderKeyByLocale = ({
  category,
  isOrdered,
  locale,
}: {
  category: string;
  isOrdered: boolean;
  locale: string;
}): 'playCount' | 'latest' => {
  const 최신정렬순을_선호하는_카테고리들: string[] = [
    CategoryQueryString.BEST,
    CategoryQueryString.PUBLIC,
  ];
  // 인기순을 선호하는 로케일일 경우 빈 값은 best 카테고리에 해당이 된다
  if (isBestCategoryDefault(locale)) {
    최신정렬순을_선호하는_카테고리들.push('');
  }

  if (최신정렬순을_선호하는_카테고리들.includes(category)) {
    return isOrdered ? 'playCount' : 'latest';
  }

  return isOrdered ? 'latest' : 'playCount';
};
